import { render, staticRenderFns } from "./EditAuth.vue?vue&type=template&id=1460e5d5&scoped=true&"
import script from "./EditAuth.vue?vue&type=script&lang=js&"
export * from "./EditAuth.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1460e5d5",
  null
  
)

export default component.exports